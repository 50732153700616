<template>
  <div class="login-container"></div>
</template>

<script>
import settings from "@/settings";
import { Loading, Message } from "element-ui";
import { getTokenInfo } from "@/api/auth";
import { setToken, getAvatarURL } from "@/utils/auth";

export default {
  name: "Login",
  components: {},
  data() {
    return {};
  },
  created() {
    let token = this.$route.query.token;
    let loadingInstance = Loading.service({
      fullscreen: true,
      background: "rgba(0, 0, 0, 0.2)",
      lock: true,
      text: "Please wait, you are redirecting..."
    });

    getTokenInfo(token)
      .then(response => {
        loadingInstance.close();
        console.log(response);
        Message({
          message: "Hi " + response.name,
          type: "success",
          duration: 5 * 1000
        });

        setToken(token);

        this.$store.dispatch("user/setInfo", {
          name: response.name,
          avatar: getAvatarURL(response.name),
          email: response.email,
          roles: ["admin"]
        });

        this.$router.push({ path: "/" });
      })
      .catch(error => {
        Message({
          message: "Login error",
          type: "error",
          duration: 5 * 1000
        });
        loadingInstance.close();
        this.$router.push({ path: "/login" });
      });
  }
};
</script>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;
.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;
}
</style>